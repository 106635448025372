import { Middleware } from 'swr';
import { useEffect } from 'react';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';

/** Types */
type ErrorType = ApiSchema<'ErrorResponse'> | undefined;


export const specialErrorCodes = (): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);

  // Destructure the error
  const error = swr?.error as ErrorType;

  // Destructure the router
  const { router } = useExtendedRouter();

  // Check if the error is a special error code
  useEffect(() => {
    if (error !== undefined) {
      const firstError = error?.errors?.[0] ? error.errors[0] : undefined;
      const firstCode = firstError?.code;

      // Handle special error codes
      switch (firstCode) {
        case 862:
          // Handle 862 error code - Account is blocked for accumulated debts
          router.push('/account-blocked');
          break;
        default:
          // Not a special error code
          break;
      }
    }
  }, [error, router]);

  return swr;
};
