import useSWRMutation from 'swr/mutation';
import { GenericV1ErrorsArray } from 'src/types/v1-api-types/_global';
import { fetcherSWRMutation } from 'src/utils/data-fetching/fetcher';
import { SwrApiMutationGlobalProps, SwrKey } from '@type/swr';
import { withToasts } from './middlewares/withToasts';
import { specialErrorCodes } from './middlewares/specialErrorCodes';


/**
 * @description Secondary useSWR hook to fetch data from API. Use it when you want to manually
 * trigger the fetch and cannot use the conditional fetch from useSwrApi. Think of it as a regular fetch on click.
 * Also, unlike useSwrApi, this hook does not share the cache with other hooks, and you cannot pass serverSideData.
 *
 * * **url**: '/v1/notifications'; required
 * * **urlParams**: '?limit=4'; optional
 * * **locale**: locale, kind of required, but we have default fallback
 * * **fetcherConfig**: optional, but we have default fallback. If you need to send a payload, use it.
 * * **apiOptions**: nice config options for SWR, i.e. { revalidate: false }; optional
 * * **toastsOptions**: nice config options for SWR toasts, i.e. { showSuccessToast: true }; optional
 *
 * For full list of **apiOptions** check this [link]{@link https://swr.vercel.app/docs/mutation#api}.
 * For full list of **fetcherConfig** check the fetcher function.
 *
 * Toasts options:
 * * **showSuccessToast**: show success toast on data change; optional
 * * **showErrorToast**: show error toast on data change; optional
 * * **successToastText**: custom success toast text; optional. If not provided, it will show a default toast (200 success).
 * * **errorToastText**: custom error toast text; optional. If not provided, it will show a default static toast (useStaticToasts).
 * * **genericErrorToast**: use generic error toast with the default text; optional
 *
 * @example
 *
 * // Model for POST, PUT, DELETE requests
 * // ***************************************************************************************
 * // Imports
 * import { useSwrMutationApi, ApiActivationServicesOk } from 'src/hooks/useSwrApi';
 *
 * // Interface for data payload
 * export interface PostData {
 *   ownApplyUrl?: string,
 *   internalAgent?: boolean,
 *   escoId?: string,
 * }
 *
 * // Build the endpoint hook (will be used in component or page)
 * // First type is the data type, second type is the payload type
 * export const useSwrActivationServicesMutation = (locale: string) => {
 *   // SWR: fetch data; you can use only this block as a non-reusable hook inside a component
 *   const {
 *     data, error, trigger, isMutating, reset,
 *   } = useSwrMutationApi<ApiActivationServicesOk, PostData>({
 *     url: '/v2/publication/activation-services',
 *     locale,
 *     fetcherConfig: {
 *       method: 'POST',
 *       stringifyPayload: true,
 *     },
 *     toastsOptions: {
 *       showSuccessToast: true,
 *       successToastText: 'Your message here',
 *       showErrorToast: true,
 *     },
 *   });
 *
 *   // return data
 *   return {
 *     data,
 *     error,
 *     trigger,
 *     isMutating,
 *     reset,
 *   };
 * };
 *
 * // Newly created hook usage (in component or page)
 * const { data, trigger } = useSwrActivationServicesMutation(locale);
 * // the endpoint usually expects a value, in this case the minimum is an empty object
 * const triggerEmpty = () => { void trigger({}); };
 * const triggerWithPayload = () => { void trigger({ internalAgent: true }); };
 *
 *
 * // Model for GET requests
 * // Used when you only need to trigger the fetch manually (even though you can use condition option on regular SWR)
 * or you rather need to fetch data without sharing the cache with other hooks.
 * // ***************************************************************************************
 * // Imports
 * import { useSwrMutationApi, ApiTagCloudOk } from 'src/hooks/useSwrApi';
 *
 * // Build the endpoint hook (will be used in component or page)
 * export const useSwrTagCloudMutation = (locale: string) => {
 *   // SWR: fetch data; you can use only this block as a non-reusable hook inside a component
 *   const {
 *     data, error, trigger, isMutating, reset,
 *   } = useSwrMutationApi<ApiTagCloudOk, undefined>({
 *     url: '/v1/tag-cloud',
 *     locale,
 *     toastsOptions: {
 *       showErrorToast: true,
 *     },
 *   });
 *
 *   // return data
 *   return {
 *     data,
 *     error,
 *     trigger,
 *     isMutating,
 *     reset,
 *   };
 * };
 *
 * // Newly created hook usage (in component or page)
 * const { data, trigger } = useSwrTagCloudMutation(locale);
 *
 * const triggerGet = () => { void trigger(); };
 *
 *
 * // Model for properly typing the trigger when passed as a prop to a child component
 * // ***************************************************************************************
 * // with payload
 * type TriggerWithPayload = <Data, Args>(arg: Args) => Promise<Data | void>;
 *
 * // without payload
 * type TriggerWithoutPayload = <Data>() => Promise<Data | void>;
 *
 * interface ComponentProps {
 *   triggerWithPayload: <Data, Args>(arg: Args) => Promise<Data | void>;
 *   triggerWithoutPayload: <Data>() => Promise<Data | void>;
 * }
 */
export const useSwrMutationApi = <DataType, ArgType>(props: SwrApiMutationGlobalProps<DataType, ArgType>) => {
  // Props destructuring
  // apiOptions full list: https://swr.vercel.app/docs/mutation#api
  // **********************************
  const {
    url, // url: '/v1/notifications', required
    urlParams = '', // urlParams: '?limit=4', optional
    locale = 'ro', // locale: locale, kind of required, but we have default fallback
    fetcherConfig,
    apiOptions = {}, // mutatorOptions: { revalidate: false }, optional
    toastsOptions = {}, // toastsOptions: { showSuccessToast: true }, optional
  } = props;


  // Complete fetch url
  // **********************************
  const fetchUrl = url + urlParams;


  // Add default middlewares to SWR options
  // **********************************
  const defaultMiddlewares = [withToasts(toastsOptions), specialErrorCodes()];
  const apiOptionsWithMiddleware = {
    // This is not an issue but better to not override this option in your endpoint hooks. Normal SWR catches the error and returns
    // it as the 'error' state, so we don't deed a catch block inside the fetcher. But swrMutation re-throws the error, but since
    // we don't have a catch block inside fetcher (because we need the 'error') you will see 'Uncaught (in promise)' in console.
    // Like I said, it's not an issue, but now you know a little more about SWR :).
    throwOnError: false,
    use: defaultMiddlewares,
    ...apiOptions,
  };


  // The SWR Hook
  // ***************************************************************************************
  const {
    data,
    error,
    trigger,
    isMutating,
    reset,
  } = useSWRMutation<DataType, GenericV1ErrorsArray, SwrKey, ArgType>(() => (
    [fetchUrl, locale || 'ro', fetcherConfig || {}]), fetcherSWRMutation, apiOptionsWithMiddleware);


  // Return the hook
  // *****************************************
  return {
    data,
    error,
    trigger,
    isMutating,
    reset,
  };
};
