import useSWR from 'swr';
import { GenericV1ErrorsArray } from 'src/types/v1-api-types/_global';
import { isApiV1Errors } from 'src/type-predicates/v1-api-predicates/_global';
import { fetcherSWR } from 'src/utils/data-fetching/fetcher';
import { SwrApiGlobalProps, ExtendedSWRResponse } from '@type/swr';
import { useMemo, useRef, useEffect } from 'react';
import { withToasts } from './middlewares/withToasts';
import { mutateEndpointSiblings } from './middlewares/mutateEndpointSiblings';
import { serverData } from './middlewares/serverData';
import { onDataChange } from './middlewares/onDataChange';
import { specialErrorCodes } from './middlewares/specialErrorCodes';


/**
 * @description Check if we can use the server side data as fallback data. We want to use the server side data for server side
 * rendering and not revalidate on mount if the data is not an error. But the data is relevant only for a specific URL and
 * URL params. If the URL or URL params change, we should not use the server side data as fallback data.
 * @param urlRef - the URL reference
 * @param urlParamsRef - the URL params reference
 * @param currentUrl - the current URL
 * @param currentParams - the current URL params
 */
const canUseFallbackData = (urlRef: string | undefined, urlParamsRef: string | undefined, currentUrl: string, currentParams: string) => {
  // just initialized, should pass
  if (!urlRef && !urlParamsRef) return true;
  // same if we have the initial case
  if (urlRef === currentUrl && urlParamsRef === currentParams) return true;
  // default case
  return false;
};


/**
 * @description The main useSWR hook to fetch data from API. You can pass serverSideData to it.
 *
 * * **url**: '/v1/notifications'; required
 * * **urlParams**: '?limit=4'; optional
 * * **shouldFetch**: fetch data based on condition, i.e. yourState, some cookie, etc.; optional
 * * **locale**: optional, but we have default fallback.
 * * **fetcherConfig**: optional, but we have default fallback. If you need to send a payload, use it.
 * * **apiOptions**: nice config options for SWR, i.e. { dedupingInterval: 10000 }; optional
 * * **toastsOptions**: nice config options for SWR toasts, i.e. { showSuccessToast: true }; optional
 * * **serverSideData**: if you have server side data, use it as initial data; the data will be checked if it's an error or not;
 * if it's not an error, it will be used as 'fallbackData' and 'revalidateOnMount' will be set to false (you don't need to fetch
 * twice, once on the server and once on the client); if the data is an error, the hook will auto-fetch on component mount; optional
 * * **onDataChange**: callback function that will be triggered on data change; same as SWR's 'onSuccess' but also runs if the data
 * is from the cache; optional
 *
 * For full list of **apiOptions** check this [link]{@link https://swr.vercel.app/docs/api#options}.
 * For full list of **fetcherConfig** check the fetcher function.
 *
 * Toasts options:
 * * **showSuccessToast**: show success toast on data change; optional
 * * **showErrorToast**: show error toast on data change; optional
 * * **successToastText**: custom success toast text; optional. If not provided, it will show a default toast (200 success).
 * * **errorToastText**: custom error toast text; optional. If not provided, it will show a default static toast (useStaticToasts).
 * * **genericErrorToast**: use generic error toast with the default text; optional
 *
 * **IMPORTANT!** do not use 'revalidateIfStale: false' apiOption, there's a bug in SWR, and it will
 * affect all hooks that use this SWR boilerplate.
 *
 * We extend the SWRInfiniteResponse with our custom properties from mutateEndpointSiblings.ts middleware.
 * * **mutateSiblings**: Mutate the sibling queries from the SWR cache. We define siblings as all cache keys that match the
 * current API URL but have different query parameters. Keep in mind that the revalidation of the siblings data
 * (network request) will happen only when that sibling is accessed again.
 *
 * @example
 *
 * // Model for GET requests (main usage)
 * // ***************************************************************************************
 * // Imports
 * import { useSwrApi, ApiLastMessagesOk } from '@hooks/useSwrApi';
 *
 * // Build the endpoint hook (will be used in component or page)
 * export const useSwrActivationServices = (locale: string, urlParams = '', serverSideData?: unknown) => {
 *   // SWR: fetch data; you can use only this block as a non-reusable hook inside a component
 *   const {
 *     data, error, isValidating, isLoading, mutate,
 *   } = useSwrApi<ApiLastMessagesOk[]>({
 *     url: '/v1/messages/last-messages',
 *     urlParams,
 *     locale,
 *     serverSideData,
 *     apiOptions: {
 *       dedupingInterval: 10000,
 *       revalidateOnFocus: false,
 *     },
 *     toastsOptions: {
 *       showSuccessToast: true,
 *       successToastText: 'Your message here',
 *       showErrorToast: true,
 *     },
 *     // Always wrap the callbackFunction in useCallback, be use it inside an useEffect
 *     onDataChange: (data) => callbackFunction(data),
 *   });
 *
 *   // return data
 *   return {
 *     data,
 *     error,
 *     isValidating,
 *     isLoading,
 *     mutate,
 *   };
 * };
 *
 * // Newly created hook usage (in component or page)
 * // data will have the ApiLastMessagesOk[] type and error will have the GenericV1ErrorsArray type
 * const { data, error, ... } = useSwrActivationServices(locale, urlParams, dataFromServerSide);
 *
 *
 * // Model for POST, PUT, DELETE requests
 * // ***************************************************************************************
 * import { useSwrApi, ApiActivationServicesOk } from 'src/hooks/useSwrApi';
 *
 * // Build the endpoint hook (will be used in component or page)
 * export const useSwrActivationServices = (locale: string, postData: object) => {
 *   // SWR: fetch data; you can use only this block as a non-reusable hook inside a component
 *   const {
 *     data, error, mutate, isValidating, isLoading,
 *   } = useSwrApi<ApiActivationServicesOk>({
 *     url: '/v2/publication/activation-services',
 *     locale,
 *     fetcherConfig: {
 *       method: 'POST',
 *       payload: postData,
 *       stringifyPayload: true,
 *     },
 *     apiOptions: {
 *       revalidateOnFocus: false,
 *       dedupingInterval: 0,
 *     },
 *     toastsOptions: {
 *       showErrorToast: true,
 *     },
 *     // Always wrap the callbackFunction in useCallback, be use it inside an useEffect
 *     onDataChange: (data) => callbackFunction(data),
 *   });
 *
 *   // return data
 *   return {
 *     data,
 *     error,
 *     mutate,
 *     isValidating,
 *     isLoading,
 *   };
 * };
 *
 * // State for post data; the hook will auto-trigger everytime this state changes which
 * // simplifies the code a lot, you don't need to call mutate() manually
 * const [postingData, setPostingData] = useState({});
 *
 * // Newly created hook usage (in component or page)
 * // data will have the ApiActivationServicesOk type and error will have the GenericV1ErrorsArray type
 * const { data, error, ... } = useSwrActivationServices(locale, postData);
 *
 *
 * // Model for Conditional Interfaces
 * // ***************************************************************************************
 * // sometimes you need to use conditional interfaces, ie: you have an endpoint that can return
 * // an array of objects or a single object, depending on the query param you send to the endpoint
 * // ie: /v1/static-lists/spoken-languages?objectResponse=1
 * // in this case, you need to use a conditional type for the data, like this (replace InterfaceArray and InterfaceObject with your imported interfaces):
 * type LocalType<T> = T extends InterfaceArray ? InterfaceArray : InterfaceObject;
 *
 * // and then write the endpoint hook like this:
 * export const useSwrSomeEndpoint = <T>(locale: string, asItemsArray = false) => {
 *  const { data, ... } = useSwrApi<LocalType<T>>({
 *    urlParams: asItemsArray ? '?asItemsArray=1' : '',
 *    ...,
 *  } ...);
 *  ...
 *  return { ... };
 *  };
 *
 *  // then, if you want the data as an object with InterfaceObject type, you do the usual:
 * const { data, error, ... } = useSwrSomeEndpoint(locale);
 *
 * // or, if you want the data as an array of objects with InterfaceArray type, you do this:
 * const { data, error, ... } = useSwrSomeEndpoint<InterfaceArray>(locale, true);
 *
 *
 * // How to use the mutateSiblings function
 * // ****************************
 * // mutate all siblings
 * mutateSiblings();
 *
 * // Usage with a compare function and a data compare function. It will mutate the siblings that match the compare function and
 * // the data compare function. Same rules for infinite lists apply. In the example below, it will mutate only the sibling with the
 * // page number 2 and the sort query parameter.
 * mutateSiblings({
 *   paramsCompareFn: (key) => key.includes('sort=true'), // => /v2/job-name?page=1&sort=true, /v2/job-name?page=2&sort=true
 *   dataCompareFn: (data) => data?.page === '2', => /v2/job-name?page=2&sort=true
 *   revalidate: true,
 * });
 *
 *
 * // How to use as a State Management Hook and replace Redux or any other state management library
 * // ***************************************************************************************
 * // Let's say we have a generic swr API hook
 * export const useSwrSomeEndpoint = (props) => {
 *   const { locale, revalidateIfStale = true } = props;
 *   const { ... } = useSwrApi<SomeInterface>({
 *    ...
 *    apiOptions: {
 *      // magic happens here
 *      revalidateIfStale,
 *    },
 *    ...
 *   });
 *  ...
 * };
 *
 * // When we want to use it a state management hook, we only need to read the data from the Cache. The good thing about it is that
 * // if there's no data in the cache, it will fetch it from the API. If the data is in the cache, it will return it instantly.
 * // All you have to do is to set the 'revalidateIfStale' to false, and you have a state management hook.
 * const { data, error, ... } = useSwrSomeEndpoint({
 *    locale,
 *    revalidateIfStale: false, // this means: don't trigger a re-fetch if we already have the data in the cache
 * });
 */
export const useSwrApi = <DataType>(props: SwrApiGlobalProps<DataType>) => {
  // Props destructuring
  // apiOptions full list: https://swr.vercel.app/docs/api#options
  // **********************************
  const {
    url, // url: '/v1/notifications', required
    urlParams = '', // urlParams: '?limit=4', optional
    shouldFetch = true, // shouldFetch: yourState, optional
    locale, // default fallback = 'ro', optional
    serverSideData, // serverSideData: initial hook data, optional
    fetcherConfig, // fetcher config object, optional
    apiOptions = {}, // ie: apiOptions: { dedupingInterval: 10000 }, optional
    toastsOptions = {}, // ie: toastsOptions: { showErrorToast: true }, optional
    onDataChange: onDataChangeCallback, // onDataChange: callback function, optional
  } = props;


  // Complete fetch url
  // **********************************
  const fetchUrl = url + urlParams;


  // Needed for initialServerData
  // **********************************
  const keyUrl = useRef<string | undefined>(undefined);
  const keyUrlParams = useRef<string | undefined>(undefined);


  // Server side data
  // **********************************
  const initialData = useMemo(() => (
    !isApiV1Errors(serverSideData) ? serverSideData as DataType : undefined
  ), [serverSideData]);


  // Add default SWR API options. You can override them in your custom endpoint hook (ie: if you pass a 'use' there,
  // it will override the one declared here).
  // **********************************
  const defaultMiddlewares = [
    serverData(fetchUrl),
    withToasts(toastsOptions),
    mutateEndpointSiblings(),
    ...(onDataChangeCallback ? [onDataChange(onDataChangeCallback)] : []),
    specialErrorCodes(),
  ];

  // 1. If we have server side data, use it as fallback data
  // 2. If we have server side data, don't revalidate on mount because we already have the data for the first render
  // 3. Disable SWR retry on error, you don't want to retry on 401, 403, or 404 and not worth it to do it for 500...
  // 4. Add default middlewares
  // 5. Add custom apiOptions from custom endpoint hook
  const apiOptionsWithMiddleware = {
    ...(initialData && canUseFallbackData(keyUrl.current, keyUrlParams.current, url, urlParams) ? { fallbackData: initialData } : null),
    ...(initialData ? { revalidateOnMount: false } : null),
    errorRetryInterval: 1000 * 60 * 60, // 1 hour
    errorRetryCount: 0,
    shouldRetryOnError: false,
    use: defaultMiddlewares,
    ...apiOptions,
  };


  // Store the key and key params to check if we can use the server side data for the current key
  useEffect(() => {
    if (!keyUrl.current) keyUrl.current = url;
    if (!keyUrlParams.current) keyUrlParams.current = urlParams;
  }, [url, urlParams]);


  // The SWR Hook
  // Remember that you can reassign the variables names to your convenience,
  // ie: data: notificationsData, error: notificationsError, etc.
  // We have the option to fetch conditionally, but it would be better to use useSWRMutation
  // Provide a default 'ro' for locale, easier for accessing the cache key with useSwrConfig
  // ***************************************************************************************
  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    mutateSiblings,
  } = useSWR<DataType, GenericV1ErrorsArray>(() => (
    shouldFetch ? [fetchUrl, locale || 'ro', fetcherConfig || {}] : null), fetcherSWR, apiOptionsWithMiddleware) as
    ExtendedSWRResponse<DataType, GenericV1ErrorsArray>;


  // Return the hook
  // *****************************************
  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    mutateSiblings,
  };
};
